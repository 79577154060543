import NavBar from "../../components/NavBar/NavBar"
import styles from  "./Home.module.css"
import Navigation from "../../components/Navigation/Navigation"

const Home = () => {
    return(
        <div className={styles.content}>
            <NavBar />
            <img src="/images/grid.jpg" className={styles.bg} alt="Grid box" />
            <div className={styles.subContent}>
                <div>
                    <h1 className={styles.title}>I'm Mattéo,</h1>
                    <h1 className={styles.title2}>a Freelance Software Engineer 🚀</h1>
                </div>
                <img src="/images/matteo.jpg" alt="Matteo Bonnet" />
            </div>
            <Navigation nextRoute="/skills" nextText="skills" />
        </div>
    )
}

export default Home